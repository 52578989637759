




































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321AbsenceForm',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      attachments: [] as File[],
      inputs: {
        medicalModel: false,
        noneRequiredModel: false,
      },
    };
  },
});
